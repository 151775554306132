<template>
  <div
    :class="((isActive == item.restaurant_id && this.$auth.setting && this.$auth.setting.product_type && this.$auth.setting.product_type.id == 2 && this.$auth.setting.store_selection == 0 && $auth.setting.manual_store_selection_by_customer == 1) ? 'active ' : '') + ' flex-grow-1 rest-item-list' + webVendorLayout">
    <router-link :to="{ name: 'vendor', params: { restaurant_name: item.slug } }"
      v-if="webVendorLayout == 1 && components == ''">
      <div v-if="!right" class="resturant-item d-flex align-items-center">
        <div :class="{ 'Schedule': (isSchedule == '1'), 'closed': (isSchedule != '1') }"
          v-if="item.restaurant_on_off == '0'" class="noclos">
          <div class="img-cls resimg-squre">
            <img :src="item.icon_image" />
          </div>
        </div>
        <div v-else class="noclos">
          <div class="img-cls resimg-squre">
            <img :src="item.icon_image" />
          </div>
        </div>
        <div class="resturant-data ms-3">
          <h4>{{ item.name }}</h4>
          <span>{{ item.cuisine_name.join(', ') }}</span>
          <div class="res-price-main mt-2 d-flex">
            <div class="star-rate"
              :class="(parseFloat(item.rating).toFixed(1) > 2.0 || parseFloat(item.rating).toFixed(1) == 0.0 ? 'green-cls' : 'red-cls')">
              <i class="fas fa-star me-2"></i>
              <span v-if="item.rating == 0">{{ parseFloat(item.rating).toFixed(1) }}</span>
              <span v-else>{{ parseFloat(item.rating).toFixed(1) }}</span>
            </div>
            <ul class="time-price d-flex justify-content-center mb-0 ms-3">
              <li>{{ item.delivery_time }}</li>
              <div v-if="item.two_person_price == 0"></div>
              <li v-else><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                parseFloat(item.two_person_price).toFixed(1) }} for two</li>
            </ul>
          </div>
          <div class="offer-lbl" v-if="item.discount_text">
            <p>{{ item.discount_text }}</p>
          </div>
        </div>
      </div>
    </router-link>
    <router-link :to="{ name: 'vendor', params: { restaurant_name: item.slug } }"
      v-if="webVendorLayout == 2 && components == ''">
      <div v-if="!right" class="resturant-item-layout2 d-flex align-items-start">
        <div :class="{ 'Schedule': (isSchedule == '1'), 'closed': (isSchedule != '1') }"
          v-if="item.restaurant_on_off == '0'" class="itemimg-thumb">
          <div class="offer-lbl" v-if="item.discount_text">
            <p>{{ item.discount_text }}</p>
          </div>
          <div class="img-cls itemimg-thumb">
            <p>{{ item.delivery_time }}</p>
            <img :src="item.icon_image" />
          </div>
        </div>
        <div v-else class="itemimg-thumb">
          <div class="offer-lbl" v-if="item.discount_text">
            <p>{{ item.discount_text }}</p>
          </div>
          <div class="img-cls">
            <p>{{ item.delivery_time }}</p>
            <img :src="item.icon_image" />
          </div>
        </div>
      </div>
      <div class="resturant-data-name resdata-nw">
        <div class="reting-name-nw">
          <h4>{{ item.name }}</h4>
          <div class="star-rate"
            :class="(parseFloat(item.rating).toFixed(1) > 2.0 || parseFloat(item.rating).toFixed(1) == 0.0 ? 'green-cls' : 'red-cls')">
            <i class="fas fa-star me-2"></i>
            <span v-if="item.rating == 0">{{ parseFloat(item.rating).toFixed(1) }}</span>
            <span v-else>{{ parseFloat(item.rating).toFixed(1) }}</span>
          </div>
        </div>
        <div class="res-price-main mt-1 d-flex justify-content-between">
          <p class="item-name-nw">{{ item.cuisine_name.join(', ') }}</p>
          <div class="time-price d-flex justify-content-center mb-0 ms-3">
            <div v-if="item.two_person_price == 0"></div>
            <p v-else><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
              parseFloat(item.two_person_price).toFixed(1) }} for two</p>
          </div>
        </div>
      </div>

    </router-link>
    <!-- <router-link :to="{name:'vendor', params:{restaurant_name: item.slug}}" v-if="webVendorLayout == 1 && components == 'navbar'"> -->
    <a href="javascript:void(0)" v-if="webVendorLayout == 1 && components == 'navbar'"
      @click="isClosedFun(item.slug, item.restaurant_id)">
      <div v-if="!right" class="resturant-item d-flex align-items-center">
        <div :class="{ 'Schedule': (isSchedule == '1'), 'closed': (isSchedule != '1') }"
          v-if="item.restaurant_on_off == '0'" class="noclos">
          <div class="img-cls resimg-squre">
            <img :src="item.icon_image" />
          </div>
        </div>
        <div v-else class="noclos">
          <div class="img-cls resimg-squre">
            <img :src="item.icon_image" />
          </div>
        </div>
        <div class="resturant-data ms-3">
          <h4>{{ item.name }}</h4>
          <span>{{ item.cuisine_name.join(', ') }}</span>
          <!-- <div class="res-price-main mt-2 d-flex">
            <div class="star-rate"
              :class="(parseFloat(item.rating).toFixed(1) > 2.0 || parseFloat(item.rating).toFixed(1) == 0.0 ? 'green-cls' : 'red-cls')">
              <i class="fas fa-star me-2"></i>
              <span v-if="item.rating == 0">{{ parseFloat(item.rating).toFixed(1) }}</span>
              <span v-else>{{ parseFloat(item.rating).toFixed(1) }}</span>
            </div>
            <ul class="time-price d-flex justify-content-center mb-0 ms-3">
              <li>{{ item.delivery_time }}</li>
              <div v-if="item.two_person_price == 0"></div>
              <li v-else><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                parseFloat(item.two_person_price).toFixed(1) }} for two</li>
            </ul>
          </div> -->
          <div class="offer-lbl" v-if="item.discount_text">
            <p>{{ item.discount_text }}</p>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: ['item', 'right', 'isPickupDelivery', 'isSchedule', 'webVendorLayout', 'components', 'businessResturant'],
  data() {
    return {
      isActive: this.base64_decode(localStorage.getItem("restaurantId"))
    }
  },

  methods: {
    isClosedFun(slug, restId) {
      window.$('#navStoreModal').modal('toggle');
      localStorage.setItem("restaurantId", this.base64_encode(restId));
      this.$emit("closedModal");
      this.$root.$emit('a-store-set-event', restId)
    }
  }
};
</script>
<style>
.rest-item-list1.active {
  background-color: #e0e0e0;
  border-radius: 16px;
}
</style>